import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VCardText,{staticClass:"py-0 px-0 px-md-4 py-md-4"},[_c(VDataTable,{ref:"tabelaCompras",attrs:{"dense":"","show-expand":"","single-expand":"","item-key":"row-key","expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"no-data-text":_vm.noData,"items-per-page":_vm.$dataTableConfig.getItemsPerPageCustom(),"footer-props":_vm.$dataTableConfig.getFooterProps(),"loading-text":_vm.$dataTableConfig.getLoadText(),"mobile-breakpoint":0},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"body.append",fn:function(ref){
var items = ref.items;
return [_c('tr',_vm._l((_vm.headers),function(header){return _c('th',{class:header.class,style:({ 'text-align': header.align !== 'right'? 'left' : 'right' })},[(header.total === 'title')?_c('span',[_vm._v("Total")]):(header.total === 'sum')?_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(typeof header.totalFormat === 'function'? header.totalFormat(_vm.data.reduce(function (sum, item) { return item[header.value] + sum; }, 0)): _vm.data.reduce(function (sum, item) { return item[header.value] + sum; }, 0)))]):(header.total === 'count')?_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(typeof header.totalFormat === 'function'? header.totalFormat(_vm.data.length): _vm.data.length))]):(header.total === 'avg')?_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.data.length > 0 ? (typeof header.totalFormat === 'function'? header.totalFormat(_vm.data.reduce(function (sum, item) { return item[header.value] + sum; }, 0) / _vm.data.length): _vm.data.reduce(function (sum, item) { return item[header.value] + sum; }, 0) / _vm.data.length) : 0))]):_c('span',[_vm._v(" ")])])}),0)]}},{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.visitado_em",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.validDate(item.visitado_em)))])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('span',[_c(VIcon,{attrs:{"small":"","title":item.fotos.length > 0 ? 'Visualise as Fotos' : 'Sem Fotos',"disabled":_vm.loading || item.fotos.length === 0},on:{"click":function($event){return _vm.verFotos(item)}}},[_vm._v(" mdi-image-multiple ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ViewComponents',{attrs:{"item":item}})],1)]}}],null,true)}),_c('VisualizarFotosDialog',{attrs:{"item":_vm.itemSelecionado},model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }