<template>
  <v-container>
    <custom-snackbar
        ref="snackbar"
    />
    <div style="max-width: 600px;">
      <strong>Comentários gerais sobre a produção:</strong>
      <p>{{ item.avaliacao_comentario }}</p>
      <hr>
    </div>
  </v-container>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
    },
  },

  computed: {
    headersItens () {
      return [
        { text: 'Talhão', value: 'talhao' },
        { text: 'Coloração OK', value: 'pergunta_coloracao' },
        { text: 'Adubação OK', value: 'pergunta_adubacao' },
        { text: 'Existem ervas daninhas', value: 'pergunta_ervas_daninha' },
        { text: 'Tipos ervas daninhas', value: 'pergunta_tipo_ervas_daninha' },
        { text: 'Danos econômicos por ervas daninhas', value: 'pergunta_danos_economicos_erva_daninha' },
        { text: 'Altura da poda está correta', value: 'pergunta_altura_poda' },
        { text: 'Sintomas', value: 'pergunta_sintoma' },
        { text: 'Danos econômicos por sintomas', value: 'pergunta_danos_economicos_sintoma' },
        { text: 'Nota de avaliação', value: 'avaliacao_nota' },
      ]
    },
  },
  methods: {
    converteUnidadeMedida (unidadeMedida, valor) {
      if (unidadeMedida === 'TN') {
        return parseFloat(valor) * 1000
      }

      return parseFloat(valor)
    },
  },
}
</script>
