<template>
  <v-dialog v-model="model" transition="dialog-bottom-transition" max-width="800">
    <custom-snackbar ref="snackbar" />
    <v-card>
      <v-toolbar dark color="info">
        <span class="headline">Visualizar Fotos</span>
      </v-toolbar>
      <v-card-text>
        <v-container v-if="item && item.fotos.length > 0">
          <v-row>
            <v-col
                v-for="(foto, idx) in item.fotos"
                cols="6"
                :key="idx"
            >
              <v-img
                :src="foto.url"
                aspect-ratio="1"
                cover
                class="elevation-1 bg-grey-lighten-2"
              ></v-img>
              <div class="mt-3">
                <span>{{ foto.descricao }}</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <span class="headline">Nenhuma foto encontrada</span>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  data: (instance) => ({
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    close () {
      this.model = false
    },
  },
}
</script>