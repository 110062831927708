import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"800"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('custom-snackbar',{ref:"snackbar"}),_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"info"}},[_c('span',{staticClass:"headline"},[_vm._v("Visualizar Fotos")])]),_c(VCardText,[(_vm.item && _vm.item.fotos.length > 0)?_c(VContainer,[_c(VRow,_vm._l((_vm.item.fotos),function(foto,idx){return _c(VCol,{key:idx,attrs:{"cols":"6"}},[_c(VImg,{staticClass:"elevation-1 bg-grey-lighten-2",attrs:{"src":foto.url,"aspect-ratio":"1","cover":""}}),_c('div',{staticClass:"mt-3"},[_c('span',[_vm._v(_vm._s(foto.descricao))])])],1)}),1)],1):_c(VContainer,[_c('span',{staticClass:"headline"},[_vm._v("Nenhuma foto encontrada")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }